html,
body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: rgb(250,250,250);
}
.container{
  margin: 70px auto 0 auto;
  width: 100%;
}
.nav-container {
  margin: 50px auto auto 0px;
  font-size: small;
}
.nav-container svg {
  color: #fff;
}
a{
  text-decoration: none;
}